import React, { useRef, useState, useEffect } from 'react';

const ScreenCapture = ({ isScreenCaptureActive }) => {
  const videoRef = useRef(null);
  const [, setStream] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  useEffect(() => {
    if (isScreenCaptureActive) {
      startScreenCapture();
    } else {
      stopScreenCapture();
    }
  }, [isScreenCaptureActive]);

  const startScreenCapture = async () => {
    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      setStream(captureStream);
      if (videoRef.current) {
        videoRef.current.srcObject = captureStream;

        const mediaRecorder = new MediaRecorder(captureStream);
        mediaRecorder.ondataavailable = event => {
          if (event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          const recordedBlob = new Blob(chunksRef.current, { type: 'video/webm' });
          chunksRef.current = [];

          // Using real-time local timestamp in the 'YYYY/MM/DD_HH:mm' format
          const currentDate = new Date();
          const timestamp =
            currentDate.getFullYear() +
            '_' +
            (currentDate.getMonth() + 1).toString().padStart(2, '0') +
            '_' +
            currentDate.getDate().toString().padStart(2, '0') +
            '_time_' +
            currentDate.getHours().toString().padStart(2, '0') +
            ':' +
            currentDate.getMinutes().toString().padStart(2, '0');

          const filename = `screenRecording_${timestamp}.webm`;

          const url = URL.createObjectURL(recordedBlob);
          const a = document.createElement('a');
          a.href = url;

          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };

        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
      }
    } catch (error) {
      console.error('Error accessing screen capture:', error);
    }
  };

  const stopScreenCapture = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setStream(null); // Clear the stream to stop the video
    }
  };

  return <video ref={videoRef} autoPlay playsInline width="100%" height="100%" style={{ objectFit: 'cover' }} />;
};

export default ScreenCapture;
