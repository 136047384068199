import React from "react";
import { MdModeEditOutline } from "react-icons/md";
import image1 from "assets/img/profile/image1.png";
import image2 from "assets/img/profile/image2.png";
import image3 from "assets/img/profile/image3.png";
import image4 from "assets/img/profile/image4.png";
import Card from "components/card";

const Project = () => {
  return (
    <Card extra={"w-full p-4  bg-gray-800/30 backdrop-blur-xl"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
        Documentation Vol.2
        </h4>
        <p className="mt-2 text-base text-gray-800 dark:text-white">
        Develop XR activities that guide users through exercises to recognize and manage emotions, promoting emotional regulation skills.
        </p>
      </div>
      {/* Project 1 */}
      <div className="flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white ">
            Empowering Minds: The XR Revolution 
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
              Project #1 .
              <a
                className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                See product details
              </a>
            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>
      {/* Project 1 */}
      <div className="mt-3 flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image3} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white">
            Virtual Horizons: Navigating Autism with XR Technology
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
              Project #1 .
              <a
                className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                See product details
              </a>
            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>
      {/* Project 1 */}
      <div className="mt-3 flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image2} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white">
            Unlocking Potential: XR Devices in Autism Therapy
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
              Project #1 .
              <a
                className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                See product details
              </a>
            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>

            {/* Project 1 */}
            <div className="mt-3 flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image4} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white">
            Digital Pathways: Enhancing Autism Care
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
              Project #1 .
              <a
                className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                See product details
              </a>
            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>
    </Card>
  );
};

export default Project;