import Card from "components/card";
import React from "react";
import Sceneboardinggate from "./Sceneboardinggate";

const General = () => {
  return (
    <Card extra={"w-full  p-3 "}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full ">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Boarding Gate
        </h4>        
        <p className="mt-2 px-2 text-base text-gray-800 dark:text-white">
        Embark on a virtual adventure while waiting at the boarding gate, as XR simulation transports you to exciting destinations, making your pre-flight moments an immersive experience.
        </p>          

      </div>

      <Sceneboardinggate />
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2 ">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Patient</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Hana
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Age </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            16
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Response</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Good
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Progress</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            80%
          </p>
        </div>



      </div>
    </Card>
  );
};

export default General;
