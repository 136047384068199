import React, { useState } from 'react';
import {
  columnsDataDevelopment,
  columnsDataComplex,
} from "./variables/columnsData";
import tableDataDevelopment from "./variables/tableDataDevelopment.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import DevelopmentTable from "./components/DevelopmentTable";
import ComplexTable from "./components/ComplexTable";
import Patienprofile from "./components/Patienprofile";
import Progress from "views/admin/Newpatient/components/Progress";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdGroupAdd, MdDoubleArrow, MdContactPhone, MdAutoStories, MdOutlineRadioButtonChecked, MdAddToQueue } from "react-icons/md";
import Widget from "components/widget/Widget";

import WidgetOne from "components/widget/WidgetOne";
import WidgetSecond from "components/widget/WidgetSecond";
import Patientformone from "./components/Patientformone";
import PatientformTwo from "./components/PatientformTwo";

const Tables = () => {

  const [visibleComponent, setVisibleComponent] = useState('A'); // Default to 'A'

  const handleButtonClick = (component) => {
    setVisibleComponent(component);
  };


  return (
    <div>
      {/* widget top ... */}
      <div className="mt-8 grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-8  ">
        <WidgetOne
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Session Information"}
          onButtonClick={handleButtonClick}
        />
     
        <WidgetSecond 
          icon={<MdGroupAdd className="h-7 w-7" />}
          title={"Add Patient"}
          onButtonClick={handleButtonClick}         
        />

        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Cognitive Report"}
        />
        <Widget
          icon={<MdContactPhone className="h-6 w-6" />}
          title={"Patient Profiles"}
        />
        <Widget
          icon={<MdOutlineRadioButtonChecked className="h-7 w-7" />}
          title={"Session Recording"}
        />
        <Widget
          icon={<MdAddToQueue className="h-6 w-6" />}
          title={"Event Creation"}
        />
        <Widget
          icon={<MdAutoStories className="h-6 w-6" />}
          title={"Notes"}
        />
        <Widget
          icon={<MdDoubleArrow className="h-6 w-6" />}
          title={"Nextstep"}
        />
      </div>

      {/* Patient List and Patient profile */}
      <div className="mt-5 grid h-full grid-cols-1 gap-5 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
        {visibleComponent === 'A' && <ComplexTable columnsData={columnsDataComplex} tableData={tableDataComplex} />}
        {visibleComponent === 'A' && <Patienprofile />} 
        {visibleComponent === 'B' && <Patientformone />}
        {visibleComponent === 'B' && <PatientformTwo />}
      </div>

      {/* Patient details and report */}
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
      {visibleComponent === 'A' && <Progress />}
      {visibleComponent === 'A' && <DevelopmentTable columnsData={columnsDataDevelopment} tableData={tableDataDevelopment} />}   


      </div>
    </div>
  );
};

export default Tables;
