import React, { useState } from 'react';

import General from "./components/General";
import Triptoairport from "./components/Triptoairport";
import Boardinggate from "./components/Boardinggate";
import Onplane from "./components/Onplane";
//import Seatselection from "./components/Seatselection";
import Onlanding from "./components/Onlanding";
import OnFitness from "./components/OnFitness";
import Notification from "./components/Notification";
import Toggleflight from "./components/Toggleflight";
import Toggletaxi from "./components/Toggletaxi";
import Toggleboarding from "./components/Toggleboarding";
import Toggleonlanding from "./components/Toggleonlanding";
import ToggleonFitness from "./components/ToggleonFitness";
import Project from "./components/Project";

import Cameraonesecond from "./components/cameraonesecond";
import Camerathird from "./components/camerathird";

import Cameraonee from "./components/cameraone";

import FixedPlugin from "components/fixedPluginstop/FixedPlugin";


import Cabinmap from "components/card/Cabinmap";
import TalktoPlayer from "components/card/TalktoPlayer";
import NFt3 from "assets/img/planemap/seats.png";
//import avatar1 from "assets/img/avatars/avatar1.png";
//import avatar2 from "assets/img/avatars/avatar2.png";
//import avatar3 from "assets/img/avatars/avatar3.png";

const ProfileOverview = () => {

  const [visibleComponent, setVisibleComponent] = useState('B'); // Default to 'B'

  const handleButtonClick = (component) => {
    setVisibleComponent(component);
  };

  return (
    <div className="flex w-full flex-col gap-5">
    <FixedPlugin />



      <div className="w-full mt-6 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-1">
      {visibleComponent === 'E' &&  <Cabinmap image={NFt3}/>}
      </div>

      <div className="w-full mt-2 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-1">
      <TalktoPlayer/>
      </div>      

        {/* tombol handling ... */}
      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">

        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Project onButtonClick={handleButtonClick}/>
        </div>
        
        {/* play scene area ... */}
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">        
        {visibleComponent === 'B' && <General />}
        {visibleComponent === 'C' && <Triptoairport />}
        {visibleComponent === 'D' && <Boardinggate />}
        {visibleComponent === 'E' && <Onplane />}
        {visibleComponent === 'F' && <Onlanding />}
        {visibleComponent === 'G' && <OnFitness />}
        </div>

        
        {/* toggle kanan ... */}
        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">          
          {visibleComponent === 'B' && <Notification />}          
          {visibleComponent === 'C' && <Toggletaxi />}
          {visibleComponent === 'D' && <Toggleboarding />}
          {visibleComponent === 'E' && <Toggleflight />}
          {visibleComponent === 'F' && <Toggleonlanding />}
          {visibleComponent === 'G' && <ToggleonFitness />}          
        </div>  

      </div>





      <div className="w-full mt-6 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-3">
      <Cameraonee />
      <Cameraonesecond />
      <Camerathird />
      </div>




    </div>
  );
};

export default ProfileOverview;