import { useState } from 'react';

const Switch = ({ color = 'brand', extra, handleswitch, ...rest }) => {
  const [isChecked, setChecked] = useState(false);

  const getSwitchColorClasses = () => {
    switch (color) {
      case 'red':
        return 'checked:bg-red-500 dark:checked:bg-red-400';
      case 'blue':
        return 'checked:bg-blue-500 dark:checked:bg-blue-400';
      // ... add more cases for other colors ...
      default:
        return 'checked:bg-brand-500 dark:checked:bg-brand-400';
            }
  };

  const switchColorClasses = getSwitchColorClasses();

  const handleClick = (e) => {
    setChecked(!isChecked);
    if (handleswitch) {
      handleswitch(!isChecked);
    }
  };

  const handleChange = (e) => {
    // Handle the change event if needed
  };

  return (
    <input
      type="checkbox"
      className={`relative h-5 w-10 appearance-none rounded-[20px] bg-[#e0e5f2] outline-none transition duration-[0.5s] 
      before:absolute before:top-[50%] before:h-4 before:w-4 before:translate-x-[2px] before:translate-y-[-50%] before:rounded-[20px]
      before:bg-white before:shadow-[0_2px_5px_rgba(0,_0,_0,_.2)] before:transition before:content-[""]
      checked:before:translate-x-[22px] hover:cursor-pointer
      dark:bg-white/5 ${switchColorClasses} ${extra}`}
      name="weekly"
      onClick={handleClick}
      onChange={handleChange}  // Add onChange handler
      checked={isChecked}
      {...rest}
    />
  );
};

export default Switch;
