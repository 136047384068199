import React from 'react';
import RangeSlider from 'react-bootstrap-range-slider';

const Slider = ({ handleslider }) => {
    const [value, setValue] = React.useState(20);
  
    const handleChange = (changeEvent) => {
      const newValue = changeEvent.target.value;
      setValue(newValue);
      handleslider(newValue); // Call the callback function with the new value
    };

    return (
        <RangeSlider
          value={value}
          onChange={handleChange}
          tooltipLabel={(currentValue) => `${currentValue}%`}
          tooltip='on'
        />
      );
    };
    
    export default Slider;
