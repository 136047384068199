import InputData from "components/fields/InputData";

import { Link } from "react-router-dom";


export default function SignIn() {
  return (
    <div className="mt-1 mb-1 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-0 lg:items-center lg:justify-start ">
      {/* Sign in section */}
      <div className="mt-[2vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-full">      

        {/* Fullname */}
        <InputData          
          extra="mb-2"
          label="Allergies"
          placeholder="Allergies"
          type="text"
        />


        <InputData          
          extra="mb-2 mt-5"
          label="Any known medical conditions"
          placeholder="Any known medical conditions"
          type="text"
        />


        <InputData          
          extra="mb-2 mt-5"
          label="Primary Care Physician's Name"
          placeholder="Primary Care Physician's Name"
          type="text"
        />


<InputData          
          extra="mb-2 mt-5"
          label="Previous Therapy"
          placeholder="Previous Therapy"
          type="text"
        />


<InputData          
          extra="mb-2 mt-5"
          label="Behavioral Triggers"
          placeholder="Behavioral Triggers"
          type="text"
        />

        <InputData          
          extra="mb-2 mt-5"
          label="Hobbies and Interests"
          placeholder="Hobbies and Interests"
          type="text"
        />

        <InputData          
          extra="mb-2 mt-5"
          label="Insurance Provider"
          placeholder="Insurance Provider"
          type="text"
        />

        <InputData          
          extra="mb-2 mt-5"
          label="Group Number "
          placeholder="Group Number "
          type="text"
        />




        {/* Checkbox */}
        {/*<div className="mt-5 mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            More Info
          </a>
        </div>*/}

       <Link to="/admin/Patients">
        <button className="linear mt-2 w-full rounded-xl bg-brand-800 py-[10px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Create Profile
        </button>
        </Link>

      </div>
    </div>
  );
}
