import React from 'react';
import { SliderPicker } from 'react-color';

const Colorpicker = ({ color, onChangeComplete }) => {
  return (
    <SliderPicker
      color={color}
      onChangeComplete={onChangeComplete}
    />
  );
};

export default Colorpicker;