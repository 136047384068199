import Card from "components/card";
import axios from 'axios';
import Switch from "components/switch";
import React from "react";
import Slider from "components/slider";

function Notification() {



    // Define slider intensity for workout 1 volume 

    async function onSlideworkout01Volume(newValue) {

      console.log("Slider Value:", newValue);
    
    // Normalize the slider value to the range of 0 to 10
        const normalizedValue = (newValue / 100) * 0.1;
    
    
        const body = {  
    
          dataType: "workout01audiovolume",
          workout01audiovolume: { workout01audiovolumeintensity: normalizedValue },
          
        };
    
      try {
        const res = await axios.put('https://autismolab.mynlab.digital/server', body);
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    }



  // Define workout 01 pawn location
  async function setlocation01() {
    const body = {
      fitnesspawnlocation: "workout01",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/fitnesspawnlocation',
        
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }



  // Define workout pawn default location  01
  async function setdefaultlocation1() {
    const body = {
      fitnesspawnlocation: "defaultlocation",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/fitnesspawnlocation',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }



  // Define workout 02 pawn location
  async function setlocation02() {
    const body = {
      fitnesspawnlocation: "workout02",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/fitnesspawnlocation',
        
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }

  // Define workout pawn default location workout 02
  async function setdefaultlocation2() {
    const body = {
      fitnesspawnlocation: "defaultlocation",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/fitnesspawnlocation',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }




  // Define workout 03 pawn location
  async function setlocation03() {
    const body = {
      fitnesspawnlocation: "workout03",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/fitnesspawnlocation',
        
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }

  // Define workout pawn default location workout 03
  async function setdefaultlocation3() {
    const body = {
      fitnesspawnlocation: "defaultlocation",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/fitnesspawnlocation',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


 // Define workout 04 pawn location
 async function setlocation04() {
  const body = {
    fitnesspawnlocation: "workout04",
  };

  try {
    const res = await axios.put(
      'https://autismolab.mynlab.digital/fitnesspawnlocation',
      
      body
    );
    console.log(res);
  } catch (err) {
    console.error(err);
  }
}

// Define workout pawn default location workout 04
async function setdefaultlocation4() {
  const body = {
    fitnesspawnlocation: "defaultlocation",
  };

  try {
    const res = await axios.put(
      'https://autismolab.mynlab.digital/fitnesspawnlocation',
      body
    );
    console.log(res);
  } catch (err) {
    console.error(err);
  }
}



// Define workout 05 pawn location
async function setlocation05() {
  const body = {
    fitnesspawnlocation: "workout05",
  };

  try {
    const res = await axios.put(
      'https://autismolab.mynlab.digital/fitnesspawnlocation',
      
      body
    );
    console.log(res);
  } catch (err) {
    console.error(err);
  }
}

// Define workout pawn default location workout 05
async function setdefaultlocation5() {
  const body = {
    fitnesspawnlocation: "defaultlocation",
  };

  try {
    const res = await axios.put(
      'https://autismolab.mynlab.digital/fitnesspawnlocation',
      body
    );
    console.log(res);
  } catch (err) {
    console.error(err);
  }
}



// Define workout 06 pawn location
async function setlocation06() {
  const body = {
    fitnesspawnlocation: "workout06",
  };

  try {
    const res = await axios.put(
      'https://autismolab.mynlab.digital/fitnesspawnlocation',
      
      body
    );
    console.log(res);
  } catch (err) {
    console.error(err);
  }
}

// Define workout pawn default location workout 06
async function setdefaultlocation6() {
  const body = {
    fitnesspawnlocation: "defaultlocation",
  };

  try {
    const res = await axios.put(
      'https://autismolab.mynlab.digital/fitnesspawnlocation',
      body
    );
    console.log(res);
  } catch (err) {
    console.error(err);
  }
}





  // Toggle play workout01
  async function playworkout1() {
    const body = {
      dataType: "workout_01",
      workout_01: "startworkout01",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // 
  async function stopworkout1() {
    const body = {
      dataType: "workout_01",
      workout_01: "stopworkout01",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }




   // Toggle play audio workout 01
   async function playAudio01() {
    const body = {
      dataType: "Musicforworkout_01",
      Musicforworkout_01: "playaudio_01",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // 
  async function stopAudio01() {
    const body = {
      dataType: "Musicforworkout_01",
      Musicforworkout_01: "stopaudio_01",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }



   // Toggle play audio workout 02
   async function playAudio02() {
    const body = {
      dataType: "Musicforworkout_01",
      Musicforworkout_01: "playaudio_02",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // 
  async function stopAudio02() {
    const body = {
      dataType: "Musicforworkout_01",
      Musicforworkout_01: "stopaudio_02",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }





    // Toggle play workout02
    async function playworkout2() {
      const body = {
        dataType: "workout_02",
        workout_02: "startworkout02",
      };
  
      try {
        const res = await axios.put(
          'https://autismolab.mynlab.digital/server',
          body
        );
        console.log(res);
      } catch (err) {
        console.error(err);
      }
    }
  
  
    // 
    async function stopworkout2() {
      const body = {
        dataType: "workout_02",
        workout_02: "stopworkout02",
      };
  
      try {
        const res = await axios.put(
          'https://autismolab.mynlab.digital/server',
          body
        );
        console.log(res);
      } catch (err) {
        console.error(err);
      }
    }



        // Toggle play workout03
        async function playworkout3() {
          const body = {
            dataType: "workout_03",
            workout_03: "startworkout03",
          };
      
          try {
            const res = await axios.put(
              'https://autismolab.mynlab.digital/server',
              body
            );
            console.log(res);
          } catch (err) {
            console.error(err);
          }
        }
      
      
        // 
        async function stopworkout3() {
          const body = {
            dataType: "workout_03",
            workout_03: "stopworkout03",
          };
      
          try {
            const res = await axios.put(
              'https://autismolab.mynlab.digital/server',
              body
            );
            console.log(res);
          } catch (err) {
            console.error(err);
          }
        }



                // Toggle play workout04
                async function playworkout4() {
                  const body = {
                    dataType: "workout_04",
                    workout_04: "startworkout04",
                  };
              
                  try {
                    const res = await axios.put(
                      'https://autismolab.mynlab.digital/server',
                      body
                    );
                    console.log(res);
                  } catch (err) {
                    console.error(err);
                  }
                }
              
              
                // 
                async function stopworkout4() {
                  const body = {
                    dataType: "workout_04",
                    workout_04: "stopworkout04",
                  };
              
                  try {
                    const res = await axios.put(
                      'https://autismolab.mynlab.digital/server',
                      body
                    );
                    console.log(res);
                  } catch (err) {
                    console.error(err);
                  }
                }


                // Toggle play workout05
                async function playworkout5() {
                  const body = {
                    dataType: "workout_05",
                    workout_05: "startworkout05",
                  };
              
                  try {
                    const res = await axios.put(
                      'https://autismolab.mynlab.digital/server',
                      body
                    );
                    console.log(res);
                  } catch (err) {
                    console.error(err);
                  }
                }
              
              
                // 
                async function stopworkout5() {
                  const body = {
                    dataType: "workout_05",
                    workout_05: "stopworkout05",
                  };
              
                  try {
                    const res = await axios.put(
                      'https://autismolab.mynlab.digital/server',
                      body
                    );
                    console.log(res);
                  } catch (err) {
                    console.error(err);
                  }
                }


                  // Toggle play workout06
                  async function playworkout6() {
                    const body = {
                      dataType: "workout_06",
                      workout_06: "startworkout06",
                    };
                
                    try {
                      const res = await axios.put(
                        'https://autismolab.mynlab.digital/server',
                        body
                      );
                      console.log(res);
                    } catch (err) {
                      console.error(err);
                    }
                  }
                
                
                  // 
                  async function stopworkout6() {
                    const body = {
                      dataType: "workout_06",
                      workout_06: "stopworkout06",
                    };
                
                    try {
                      const res = await axios.put(
                        'https://autismolab.mynlab.digital/server',
                        body
                      );
                      console.log(res);
                    } catch (err) {
                      console.error(err);
                    }
                  }


                



  return (
    <Card extra={"w-full  p-3"}>
      
      <div className="relative mb-3 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Workout Audio 
        </h4>        
      </div>

      <div className="flex flex-col">
        {/* the custom checkbox desing added in src/index.js */}
            <div className="mt-3 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? playAudio01() : stopAudio01())} />
              <label
                htmlFor="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Music 01 
              </label>
            </div>

            <div className="mt-3 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? playAudio02() : stopAudio02())} />
              <label
                htmlFor="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Music 02 
              </label>
            </div>

            <div className="mt-5 flex items-center gap-3">        
              <label
            htmlFor="checkbox7"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Audio Volume
              </label>
            </div>

            <div className="mt-1">
              <Slider handleslider={onSlideworkout01Volume} />
            </div> 

      </div>
      

      
      <div className="relative mb-3 mt-8 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Workout 01
        </h4>        
      </div>

      <div className="flex flex-col">
        {/* the custom checkbox desing added in src/index.js */}
            <div className="mt-3 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? setlocation01() : setdefaultlocation1())} />
              <label
                htmlFor="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Enable / Disable 
              </label>
            </div>

            <div className="mt-2 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? playworkout1() : stopworkout1())} />
              <label
                htmlFor="checkbox2"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Start 
              </label>
            </div>


      </div>



      <div className="relative mb-3 mt-8 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Workout 02
        </h4>        
      </div>

      <div className="flex flex-col">
        {/* the custom checkbox desing added in src/index.js */}
            <div className="mt-3 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? setlocation02() : setdefaultlocation2())} />
              <label
                htmlFor="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Enable / Disable 
              </label>
            </div>

            <div className="mt-2 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? playworkout2() : stopworkout2())} />
              <label
                htmlFor="checkbox2"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Start 
              </label>
            </div>


      </div>



      <div className="relative mb-3 mt-8 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Workout 03 
        </h4>        
      </div>

      <div className="flex flex-col">
        {/* the custom checkbox desing added in src/index.js */}
            <div className="mt-3 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? setlocation03() : setdefaultlocation3())} />
              <label
                htmlFor="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Enable / Disable 
              </label>
            </div>

            <div className="mt-2 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? playworkout3() : stopworkout3())} />
              <label
                htmlFor="checkbox2"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Start 
              </label>
            </div>
      </div>



      <div className="relative mb-3 mt-8 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Workout 04 
        </h4>        
      </div>

      <div className="flex flex-col">
        {/* the custom checkbox desing added in src/index.js */}
            <div className="mt-3 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? setlocation04() : setdefaultlocation4())} />
              <label
                htmlFor="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Enable / Disable 
              </label>
            </div>

            <div className="mt-2 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? playworkout4() : stopworkout4())} />
              <label
                htmlFor="checkbox2"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Start 
              </label>
            </div>
      </div>



      <div className="relative mb-3 mt-8 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Workout 05
        </h4>        
      </div>

      <div className="flex flex-col">
        {/* the custom checkbox desing added in src/index.js */}
            <div className="mt-3 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? setlocation05() : setdefaultlocation5())} />
              <label
                htmlFor="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Enable / Disable 
              </label>
            </div>

            <div className="mt-2 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? playworkout5() : stopworkout5())} />
              <label
                htmlFor="checkbox2"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Start 
              </label>
            </div>
      </div>



      <div className="relative mb-3 mt-8 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Workout 06
        </h4>        
      </div>

      <div className="flex flex-col">
        {/* the custom checkbox desing added in src/index.js */}
            <div className="mt-3 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? setlocation06() : setdefaultlocation6())} />
              <label
                htmlFor="checkbox1"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Enable / Disable 
              </label>
            </div>

            <div className="mt-2 flex items-center gap-3">
            <Switch handleswitch={(isChecked) => (isChecked ? playworkout6() : stopworkout6())} />
              <label
                htmlFor="checkbox2"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Start 
              </label>
            </div>
      </div>




    </Card>
  );
}

export default Notification;
