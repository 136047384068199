import MiniCalendar from "components/calendar/MiniCalendar";

import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";

const Dashboard = () => {
  return (
    <div>
      {/* Card widget */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-7 ">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Explore XR"}
          subtitle={"80%"}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"VR Research"}
          subtitle={"20%"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Therapy Findings"}
          subtitle={"50%"}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Discover XR Autism"}
          subtitle={"70%"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Unlock VR Therapy"}
          subtitle={"70%"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Autism VR Insights"}
          subtitle={"40%"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Autism VR Insights"}
          subtitle={"40%"}
        />
      </div>


      
      {/* Charts */}


      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 ">
      <DailyTraffic />
          <PieChartCard />
          <DailyTraffic />
          <DailyTraffic />

          </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />          
        </div>

        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[10px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
          <DailyTraffic />
          <DailyTraffic />
        </div>



        {/* Complex Table , Task & Calendar */}

        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />

        {/* Task chart & Calendar */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2 ">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div>
      </div>



    </div>
  );
};

export default Dashboard;
