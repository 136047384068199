/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          
          
          <Link key={index} to={route.layout + "/" + route.path}>
<div className="relative mb-2 flex p-1 rounded-md ">
  <li className="flex items-center px-7" key={index}>
    <span
      className={`${
        activeRoute(route.path) === true
          ? "font-bold text-brand-500 dark:text-white"
          : "font-medium text-gray-800 dark:text-white"
      }`}
    >
      {route.icon ? route.icon : <DashIcon />}{" "}
    </span>
    <p
      className={`leading-1 ml-4 flex ${
        activeRoute(route.path) === true
          ? "font-bold text-navy-700 dark:text-white"
          : "font-medium text-gray-800 dark:text-white"
      }`}
    >
      {route.name}
    </p>
  </li>
  {activeRoute(route.path) ? (
    <div className="absolute right-0 top-0 h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
  ) : null}
</div>
          </Link>
          
          


        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
