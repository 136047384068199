// Chakra Imports
// Custom Icons
import React from "react";
import axios from 'axios';

import { MdOutlineStopCircle } from "react-icons/md";
export default function FixedPlugin(props) {

  const [darkmode, ] = React.useState(
    document.body.classList.contains("dark")
  );

    // quitegameplay

    async function quitegameplay() {
      // First request to set from_plane_pawn to "reset_gameplay"
      const Body = {
        dataType: "stopgameplay",
        stopgameplay: "quite_game",
      };
  
      try {
        const resetRes = await axios.put('https://autismolab.mynlab.digital/server', Body);
        console.log(resetRes);
  
        // Simulate a delay (e.g., 500 milliseconds) and then send a second request to set from_plane_pawn to "null"
        setTimeout(async () => {
          const Body = {
            dataType: "stopgameplay",
            stopgameplay: "null",
          };
  
          try {
            const nullRes = await axios.put('https://autismolab.mynlab.digital/server', Body);
            console.log(nullRes);
          } catch (nullErr) {
            console.error(nullErr);
          }
        }, 800);
      } catch (resetErr) {
        console.error(resetErr);
      }
    }
  


  return (
    <button
className="border-px fixed bottom-[30px] right-[35px] !z-[99] flex h-[60px] w-[60px] items-center justify-center rounded-full border-[#ff0303] bg-red-500 p-0"
onClick={quitegameplay}
    >

      <div className="cursor-pointer text-gray-600">
        {darkmode ? (
          <MdOutlineStopCircle className="h-10 w-10 text-white" />
        ) : 
        
        (
          <MdOutlineStopCircle className="h-10 w-10 text-white" />
        )}
      </div>
    </button>
  );
}
