import { useState } from "react";
import Card from "components/card";
import axios from 'axios';

const TalktoPatient = ({ image, extra }) => {
  const [inputValue, setInputValue] = useState('');

  const handleButtonClick = async () => {
    const body = {
      talktoplayer: { messageinput: inputValue },
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/talktoplayer',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }

    // Clear the input value after the button click
    setInputValue('');
  };

  return (
    <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-gray-800/30 backdrop-blur-xl ${extra}`}>
      {/* Header */}
      <div className="mt-2 mb-2 w-full ">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Talk to Patient
        </h4>
        <p className="mt-2 px-2 text-base text-gray-800 dark:text-white">
          Type any message and send it to patient
        </p>
      </div>

      <div className="h-full w-full ">
        <div className="relative w-full">
          <img
            src={image}
            className="mb-3 h-full w-full rounded-xl 3xl:h-full 3xl:w-full"
            alt=""
          />
        </div>

        <div style={{ textAlign: 'center', marginTop: '5px' }} className=" mb-5 ">
          <input
            type="text"
            value={inputValue} // Bind the input value to the state
            onChange={(e) => setInputValue(e.target.value)} // Update state on change
            style={{
              marginRight: '10px',
              padding: '8px',
              backgroundColor: '#f2f2f2',
              borderRadius: '10px',
              border: '1px solid #ccc',
              width: '35%',
            }}
          />
          <button
            onClick={handleButtonClick} // Clear input after button click
            className=" mt-6 linear rounded-[20px] bg-brand-500 px-4 py-2 text-sm font-normal text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Send Message
          </button>
        </div>
      </div>
    </Card>
  );
};

export default TalktoPatient;