import React from "react";
import { MdModeEditOutline } from "react-icons/md";
import image1 from "assets/img/profile/athome.jpg";
import image2 from "assets/img/profile/boardinggate.jpg";
import image3 from "assets/img/profile/atTaxi.jpg";
import image4 from "assets/img/profile/onplane.jpg";
import image5 from "assets/img/profile/landing.jpg";
import image6 from "assets/img/profile/fitness.jpg";
import Card from "components/card";

const Project = ({ onButtonClick }) => {
  return (
    <Card extra={"w-full p-4  bg-gray-800/30 backdrop-blur-xl"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Simulation Events
        </h4>
        <p className="mt-2 text-base text-gray-800 dark:text-white">
      choose one of the simulation events
        </p>
      </div>

      {/* Project 1 */}
      <div className="flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="" />
          </div>
          <div className="ml-4 ">
            <p className="text-base font-medium text-navy-700 dark:text-white">
              At Home
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
              
            <button
            onClick={() => onButtonClick('B')}
            className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Activate
          </button>

            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>

      {/* Project 1 */}
       <div className="flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image6} alt="" />
          </div>
          <div className="ml-4 ">
            <p className="text-base font-medium text-navy-700 dark:text-white">
              Personal Coach
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
              
            <button
            onClick={() => onButtonClick('G')}
            className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Activate
          </button>

            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>
      
 
      {/* Project 1 */}
      <div className="mt-3 flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image3} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-gray-500 dark:text-white">
              Trip to Airport
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
            <button
            onClick={() => onButtonClick('C')}
            className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Activate
          </button>
            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>
      {/* Project 1 */}
      <div className="mt-3 flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image2} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-gray-500 dark:text-white">
              Boarding Gate
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
            <button
            onClick={() => onButtonClick('D')}
            className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Activate
          </button>
            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>

      {/* Project 1 */}
      <div className="mt-3 flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image4} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white">
              on Airplane
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
            <button
            onClick={() => onButtonClick('E')}
            className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Activate
          </button>
            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>


      {/* Project 1 */}
      <div className="mt-3 flex w-full items-center justify-between rounded-2xl bg-gray-800/5 backdrop-blur-xl p-3 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image5} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-gray-500 dark:text-white">
              Landing
            </p>
            <p className="mt-2 text-sm text-gray-800 dark:text-white">
            <button
            onClick={() => onButtonClick('F')}
            className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Activate
          </button>
            </p>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-800 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>

    </Card>
  );
};

export default Project;
