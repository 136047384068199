import React from "react";
// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Xrsimulation from "views/admin/xrsimulation";
import Newpatient from "views/admin/Newpatient";
import Learning from "views/admin/learning";
import Documentation from "views/admin/documentation";
import DataTables from "views/admin/tables";


// Auth Imports


// Icon Imports
import {
  MdHomeMax,
  MdOutlinePersonAddAlt,   
  MdAddchart,
  MdOutlineFlightTakeoff,
  MdOutlineAppRegistration,
  MdOutlineWidgets,
  MdAssignmentInd,
   
} from "react-icons/md";

const routes = [

  {
    name: "Home",
    layout: "/admin",
    path: "default",
    icon: <MdHomeMax className="h-6 w-6" />,
    component: <NFTMarketplace />,
    
  },

  {
    name: "Report",
    layout: "/admin",
    path: "Report",
    icon: <MdAddchart className="h-6 w-6" />,
    component: <MainDashboard />,
    secondary: true,
    
   },


   {
    name: "New Patient",
    layout: "/admin",
    path: "add-new-patient",
    icon: <MdOutlinePersonAddAlt className="h-6 w-6" />,
    component: <Newpatient />,
   
  },

  {
    name: "All Patients",
    layout: "/admin",
    icon: <MdAssignmentInd className="h-6 w-6" />,
    path: "Patients",
    component: <DataTables />,
  },


  {
    name: "Learning",
    layout: "/admin",
    path: "learning",
    icon: <MdOutlineAppRegistration className="h-6 w-6" />,
    component: <Learning />,
  },

  {
    name: "Documentation",
    layout: "/admin",
    path: "Documentation",
    icon: <MdOutlineWidgets className="h-6 w-6" />,
    component: <Documentation />,
  },
  
  {
    name: "XR Simulation",
    layout: "/admin",
    path: "XRsimulation",
    icon: <MdOutlineFlightTakeoff className="h-6 w-6" />,
    component: <Xrsimulation />,
   
  },




];
export default routes;
