
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  
} from "react-table";
import { MdCheckCircle, MdChangeCircle, MdOutlineError } from "react-icons/md";
import { useMemo } from "react";
import Progress from "components/progress";
import { FiSearch } from "react-icons/fi";





const ComplexTable = (props) => {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  return (



    <Card extra={"w-full  p-5 sm:overflow-x-auto bg-gray-800/30 backdrop-blur-xl"}>
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Patient List
        </div>
        {/* <CardMenu/> */}
      </div>

      <div className="mt-5 flex h-[50px] items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[200px]">
        <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>




      <div className="mt-4 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-20 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-800 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";





                    if (cell.column.Header === "NAMES") {
                        data = (
                          <button className="text-sm font-bold text-navy-700 dark:text-white bg-transparent hover:bg-gray-800 hover:bg-opacity-25 transition duration-300 ease-in-out rounded-full px-3 py-1 m-1">
                            {cell.value}
                          </button>
                        );
                      }
                    
                    
                    
                    else if (cell.column.Header === "STATUS") {
                      data = (
                        <div className="flex items-center gap-2">
                        

                          <div className={`rounded-full text-xl`}>
                            {
                              
                              cell.value === "Complete" ? (
                              <MdCheckCircle className="text-green-500" />
                            ) : 
                                                        
                            cell.value === "ongoing" ? (
                              <MdChangeCircle className="text-gray-800" />
                            ) : 
                            
                            cell.value === "Error" ? (
                              <MdOutlineError className="text-orange-500" />
                            ) : 
                            null
                            
                            }
                          </div>


                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>



                        </div>
                      );
                    } 
                    
                    
                    
                    else if (cell.column.Header === "ID-Number") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } 
                    
                    
                    else if (cell.column.Header === "PROGRESS") {
                      data = <Progress width="w-[68px]" value={cell.value} />;
                    }



                    else if (cell.column.Header === "ACTION") {
                      data = (
                        <button className="text-sm font-bold text-navy-700 dark:text-white bg-transparent hover:bg-gray-800 hover:bg-opacity-25 transition duration-300 ease-in-out rounded-full px-3 py-1 m-1">
                            {cell.value}
                          </button>
                      );
                    } 



                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
