import Card from "components/card";
import React from "react";
import ForminputTwo from "views/admin/Newpatient/components/ForminputTwo";


const General = () => {
  return (
    <Card extra={"w-full  p-6 "}>
      {/* Header */}
      <div className="mt-2 mb-1 w-full ">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Patient Registration
        </h4>        
        <p className="mt-2 px-2 text-base text-gray-800 dark:text-white">
        Specific Information :
        </p>                  
      </div>

      <div>
        <ForminputTwo />
      </div>     
     
    </Card>
  );
};

export default General;
