/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import avatar from "assets/img/avatars/autismoLab.png";


// material-ui


const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

<div className="mx-[20px] mt-[30px] flex items-center flex-col">
  <div className="h-3" style={{ paddingBottom: '30px' }}>
    <img
      className="h-20 rounded-full transform scale-75" // Adjusted scale
      src={avatar}
      alt="Elon Musk"
    />
  </div>
</div>


<div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30 " />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      {/* Free Horizon Card */}


      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
