
import General from "./components/General";
import TaskCardlearning from "views/admin/learning/components/TaskCardlearning";
import Project from "./components/Project";


const Learning = () => {
  return (
    <div className="flex w-full flex-col gap-2">
      <div className="w-ful mt-5 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">




      </div>
      {/* all project & ... */}

      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4 ">
          <Project />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <General />
        </div>

        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          <TaskCardlearning />
        </div>

      </div>





    </div>
  );
};

export default Learning;
