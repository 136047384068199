import Card from "components/card";
import React from "react";

const General = () => {
  return (
    <Card extra={"w-full  p-3 bg-gray-800/30 backdrop-blur-xl"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          General Information
        </h4>
        <p className="mt-2 px-2 text-base text-gray-800 dark:text-white">
        XR therapy for autism represents a groundbreaking approach that harnesses the power of immersive 
        technologies to address various challenges faced by individuals on the autism spectrum. From social
        interaction simulations to sensory integration exercises, this innovative therapy offers a personalized
        and controlled environment for individuals with autism to develop essential skills.
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white"> Therapy</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
          Navigating Neurodiversity
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Intervention</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
          Immersive Solutions
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Care</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
          Beyond Reality
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Bridges</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
          Virtual Bridges
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Support</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
          Enhancing Lives
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Advancements</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
          Innovation in Action
          </p>
        </div>
      </div>
    </Card>
  );
};

export default General;
