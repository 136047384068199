import React from "react";
import banner from "assets/img/profile/athome.jpg";
import Card from "components/card";
import axios from 'axios';

// Icon Imports
import {

  MdChangeCircle,
  MdOutlinePlayCircle,
  MdMotionPhotosPaused,
  MdOutlineStopCircle,
   
} from "react-icons/md";


const Banner = () => { 

//* play house event *//

  async function onClick() {

    const Body = {
      dataType: "from_fitness_pawn",
      from_fitness_pawn: "to_home_pawn",
    };

    try {
      const resetRes = await axios.put('https://autismolab.mynlab.digital/server', Body);
      console.log(resetRes);

      // Simulate a delay (e.g., 500 milliseconds) and then send a second request to set from_plane_pawn to "null"
      setTimeout(async () => {
        const Body = {
          dataType: "from_fitness_pawn",
          from_fitness_pawn: "null",
        };

        try {
          const nullRes = await axios.put('https://autismolab.mynlab.digital/server', Body);
          console.log(nullRes);
        } catch (nullErr) {
          console.error(nullErr);
        }
      }, 800);
    } catch (resetErr) {
      console.error(resetErr);
    }
  }


  async function onClickReset() {
    // First request to set from_plane_pawn to "reset_gameplay"
    const Body = {
      dataType: "from_plane_pawn",
      from_plane_pawn: "reset_gameplay",
    };

    try {
      const resetRes = await axios.put('https://autismolab.mynlab.digital/server', Body);
      console.log(resetRes);

      // Simulate a delay (e.g., 500 milliseconds) and then send a second request to set from_plane_pawn to "null"
      setTimeout(async () => {
        const Body = {
          dataType: "from_plane_pawn",
          from_plane_pawn: "null",
        };

        try {
          const nullRes = await axios.put('https://autismolab.mynlab.digital/server', Body);
          console.log(nullRes);
        } catch (nullErr) {
          console.error(nullErr);
        }
      }, 800);
    } catch (resetErr) {
      console.error(resetErr);
    }
  }

  async function quitegameplay() {
    // First request to set from_plane_pawn to "reset_gameplay"
    const Body = {
      dataType: "stopgameplay",
      stopgameplay: "quite_game",
    };

    try {
      const resetRes = await axios.put('https://autismolab.mynlab.digital/server', Body);
      console.log(resetRes);

      // Simulate a delay (e.g., 500 milliseconds) and then send a second request to set from_plane_pawn to "null"
      setTimeout(async () => {
        const Body = {
          dataType: "stopgameplay",
          stopgameplay: "null",
        };

        try {
          const nullRes = await axios.put('https://autismolab.mynlab.digital/server', Body);
          console.log(nullRes);
        } catch (nullErr) {
          console.error(nullErr);
        }
      }, 800);
    } catch (resetErr) {
      console.error(resetErr);
    }
  }


  return (
    <Card extra={"mb-5 items-center w-full p-[16px] bg-cover bg-gray-800/5 backdrop-blur-xl"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex w-full justify-center rounded-xl bg-cover bg-center"
        style={{
        backgroundImage: `url(${banner})`,
        height: '300px', // Set a default height for larger screens
        }}>
      </div>

      {/* Post followers */}
      
      <div className="mt-6 mb-3 flex flex-col sm:flex-row gap-4 md:!gap-4">

<div className="flex flex-col items-center justify-center">
  <button className="linear rounded-[20px] bg-brand-900 px-2 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
    onClick={onClick}
  >
    <div className="flex items-center">
      <div className="flex h-7 w-7 items-center justify-center ">
        <MdOutlinePlayCircle className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
      </div>
      <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
        Play
      </h4>
    </div>
  </button>
</div>

<div className="flex flex-col items-center justify-center">
  <button className="linear rounded-[20px] bg-brand-900 px-2 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
    <div className="flex items-center">
      <div className="flex h-7 w-7 items-center justify-center ">
        <MdMotionPhotosPaused className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
      </div>
      <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
        Pause
      </h4>
    </div>
  </button>
</div>

<div className="flex flex-col items-center justify-center">
        <button className="linear rounded-[20px] bg-brand-900 px-2 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          onClick={onClickReset}
        >
          <div className="flex items-center">
            <div className="flex h-7 w-7 items-center justify-center ">
              <MdChangeCircle className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
            </div>
            <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
              Reset
            </h4>
          </div>
        </button>
      </div>

      <div className="flex flex-col items-center justify-center">
        <button className="linear rounded-[20px] bg-red-500 px-1 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-red-800 active:bg-brand-700 dark:bg-red-500 dark:hover:bg-red-400 dark:active:opacity-90"
          onClick={quitegameplay}
        >
          <div className="flex items-center">
            <div className="flex h-7 w-7 items-center justify-center ">
              <MdOutlineStopCircle className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
            </div>
            <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
              Stop
            </h4>
          </div>
        </button>
      </div>

</div>



    </Card>
  );
};

export default Banner;
