//import CheckTable from "./components/CheckTable";

import {
  //columnsDataCheck,
  //columnsDataColumns,
  //columnsDataComplex,
  columnsDataComplextwo,
} from "./variables/columnsData";

//import tableDataCheck from "./variables/tableDataCheck.json";
//import tableDataColumns from "./variables/tableDataColumns.json";
//import tableDataComplex from "./variables/tableDataComplex.json";
import tableDataComplextwo from "./variables/tableDataComplextwo.json";
//import ColumnsTable from "./components/ColumnsTable";
//import ComplexTable from "./components/ComplexTable";
import ComplexTabletwo from "./components/ComplexTabletwo";



const Tables = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <ComplexTabletwo
          columnsData={columnsDataComplextwo}
          tableData={tableDataComplextwo}
        />
        {/* hide */}
        {/*<CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />*/}
      </div>

      {/*<div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
        <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        />

        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
      </div>*/}


    </div>
  );
};

export default Tables;
