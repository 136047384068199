import React, { useState } from 'react';
import Card from "components/card";
import ScreenCapture from './ScreenCapture';
import {
  MdAdjust,
  MdOutlineStopCircle,
} from "react-icons/md";

import "./Banner.css"; // Import your CSS file for styling

const Screenrecording = () => {
  const [isRecording, setIsRecording] = useState(false);

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = () => {
    console.log('Recording started');
    setIsRecording(true);
  };

  const stopRecording = () => {
    console.log('Recording stopped');
    setIsRecording(false);
  };

  return (
    <Card extra={"mb-5 items-center w-full  p-[16px] bg-cover bg-gray-800/5 backdrop-blur-xl"}>
      <div className="relative mt-1 flex h-32 w-full justify-center rounded-xl overflow-hidden">
      <ScreenCapture isScreenCaptureActive={isRecording} />
      </div>

      <div className="mt-6 mb-3 flex gap-3 md:!gap-2">
        <div className="flex flex-col items-center justify-center">
          <button
            onClick={toggleRecording}
            className={`linear rounded-[20px] px-2 py-0.5 text-sm font-normal text-white transition duration-200 ${isRecording ? 'recording-btn' : 'not-recording-btn'}`}
          >
            <div className="flex items-center">
              <div className="flex h-7 w-7 items-center justify-center">
                <MdAdjust className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
              </div>
              <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
                {isRecording ? 'Recording' : 'Record'}
              </h4>
            </div>
          </button>
        </div>

        <div className="flex flex-col items-center justify-center">
          <button
            onClick={toggleRecording}
            className={`linear rounded-[20px] bg-red-500 px-1 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-red-600 active:bg-brand-700 dark:bg-red-500 dark:hover:bg-red-400 dark:active:opacity-90`}
          >
            <div className="flex items-center">
              <div className="flex h-7 w-7 items-center justify-center">
                <MdOutlineStopCircle className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
              </div>
              <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
                Stop
              </h4>
            </div>
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Screenrecording;
