import React, { useState } from 'react';
import Card from "components/card";
import Slider from "components/slider";
import Colorpicker from "components/colorpicker";
import Switch from "components/switch";
import axios from 'axios';

function Notification() { 

  // Toggle Move to Runway
  async function startplane() {
    const body = {
      dataType: "flightsimulation",
      flightsimulation: "movetorunway",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // 
  async function stopplane() {
    const body = {
      dataType: "flightsimulation",
      flightsimulation: "stopmovetorunway",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // Turn exterior camera
  async function turnExterior() {
    const body = {
      objectPath: "/Game/Simulator.Simulator:PersistentLevel.VRSpectator_C_1",
      functionName: "toggleexterior",
      parameters: {

      },
      generateTransaction: true,
    };

    try {
      const res = await axios.put(
        'http://localhost:30010/remote/object/call',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // 
  async function turnInterior() {
    const body = {
      objectPath: "/Game/Simulator.Simulator:PersistentLevel.VRSpectator_C_1",
      functionName: "toggleexterior",
      parameters: {

      },
      generateTransaction: true,
    };

    try {
      const res = await axios.put(
        'http://localhost:30010/remote/object/call',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // Toggle Show Hide people passangers 
  async function showpeople() {
    const body = {
      dataType: "peoplevisibility",
      peoplevisibility: "showpeople",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // 
  async function hidepeople() {
    const body = {
      dataType: "peoplevisibility",
      peoplevisibility: "hidepeople",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }





  // Toggle open close window
  async function openWindow() {
    const body = {
      dataType: "windowCOndition",
      windowCOndition: "openWindow",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // 
  async function closeWindow() {
    const body = {
      dataType: "windowCOndition",
      windowCOndition: "closeWindow",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }







  // Define Flight intro On
  async function turnEngineOn() {
    const body = {
      dataType: "flightintroduction",
      flightintroduction: "flightintroductionOn",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }



  // Define Flight intro Off
  async function turnEngineOff() {
    const body = {
      dataType: "flightintroduction",
      flightintroduction: "flightintroductionOff",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',
        body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }



  // flight Instructions TESTING
  async function InstructionOn() {
    const body = {
      dataType: "flightinstruction",
      flightinstruction: "playinstruction",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }


  // 
  async function InstructionOff() {
    const body = {
      dataType: "flightinstruction",
      flightinstruction: "stopinstruction",
    };

    try {
      const res = await axios.put(
        'https://autismolab.mynlab.digital/server',body
      );
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  }
  




// Define button ceiling light and color

  const API_URL_1 = 'https://autismolab.mynlab.digital/server';
  const API_URL_2 = 'https://autismolab.mynlab.digital/server';
  
  async function ceilinglightON() {
    const body1 = {
      dataType: "CeilingIntensity",
      CeilingIntensity: { valueintensity: 1 },
    };
  
    const body2 = {
      dataType: "Ceilingcolortest",
      Ceilingcolortest: { colorforceiling: [210, 121, 145] },
    };
  
    try {
      // Use Promise.all to send requests simultaneously
      const [res1, res2] = await Promise.all([
        axios.put(API_URL_1, body1),
        axios.put(API_URL_2, body2),
      ]);
  
      console.log('Response 1:', res1);
      console.log('Response 2:', res2);
    } catch (err) {
      console.error(err.response || err);
    }
  }
  
  async function ceilinglightOFF() {
    const body1 = {
      dataType: "CeilingIntensity",
      CeilingIntensity: { valueintensity: 0 },
    };
  
    const body2 = {
      dataType: "Ceilingcolortest",
      Ceilingcolortest: { colorforceiling: [210, 121, 145] },
    };
  
    try {
      // Use Promise.all to send requests simultaneously
      const [res1, res2] = await Promise.all([
        axios.put(API_URL_1, body1),
        axios.put(API_URL_2, body2),
      ]);
  
      console.log('Response 1:', res1);
      console.log('Response 2:', res2);
    } catch (err) {
      console.error(err.response || err);
    }
  }
  


// Define slider intensity for ceiling

async function onSlide(newValue) {

  console.log("Slider Value:", newValue);


// Normalize the slider value to the range of 0 to 10
    const normalizedValue = (newValue / 100) * 10;


    const body = {      
      
      dataType: "CeilingIntensity",
      CeilingIntensity: { valueintensity: normalizedValue },
      
    };

  try {
    const res = await axios.put('https://autismolab.mynlab.digital/server', body);
    console.log(res);
  } catch (err) {
    console.log(err);
  }
}



    // Define slider intensity for enginevolume

    async function onSlideEngineVolume(newValue) {

      console.log("Slider Value:", newValue);
    
    // Normalize the slider value to the range of 0 to 10
        const normalizedValue = (newValue / 100) * 0.5;
    
    
        const body = {  
    
          dataType: "Enginevolume",
          Enginevolume: { Enginevolumeintensity: normalizedValue },
          
        };
    
      try {
        const res = await axios.put('https://autismolab.mynlab.digital/server', body);
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    }



    // Define slider intensity for flight introduction volume

    async function onSlideFlightIntroVolume(newValue) {

      console.log("Slider Value:", newValue);
    
    // Normalize the slider value to the range of 0 to 10
        const normalizedValue = (newValue / 100) * 0.5;
    
    
        const body = {  
    
          dataType: "FlightIntrovolume",
          FlightIntrovolume: { FlightIntrovolumeintensity: normalizedValue },
          
        };
    
      try {
        const res = await axios.put('https://autismolab.mynlab.digital/server', body);
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    }



        // Define slider intensity for flight Instruction video volume

        async function onSlideFlightinstructionvolume(newValue) {

          console.log("Slider Value:", newValue);
        
        // Normalize the slider value to the range of 0 to 10
            const normalizedValue = (newValue / 100) * 0.5;
        
        
            const body = {  
        
              dataType: "Flightinstructionvolume",
              Flightinstructionvolume: { Flightinstructionvolumeintensity: normalizedValue },
              
            };
        
          try {
            const res = await axios.put('https://autismolab.mynlab.digital/server', body);
            console.log(res);
          } catch (err) {
            console.log(err);
          }
        }








    // Define color for ceiling
    const [selectedColor, setSelectedColor] = useState({ r: 255, g: 0, b: 0 });

    
    async function onColorChange(newColor) {
      console.log('Selected Color:', newColor);
      setSelectedColor(newColor.rgb);
  
      const body = {

        dataType: "Ceilingcolortest",
        Ceilingcolortest: { colorforceiling: [newColor.rgb.r, newColor.rgb.g, newColor.rgb.b] },

      };
  
      try {
        const res = await axios.put(
          'https://autismolab.mynlab.digital/server',body );
        console.log(res);
      } catch (err) {
        console.error(err);
      }
    }


   


// Define toggle window light and color

const API_URL_3 = 'https://autismolab.mynlab.digital/server';
const API_URL_4 = 'https://autismolab.mynlab.digital/server';

async function windowlightON() {
  const body1 = {
    dataType: "windowlightintensity",
    windowlightintensity: { windowvalueintensity: 1 },
  };

  const body2 = {
    dataType: "windowcolorlight",
    windowcolorlight: { colorforwindow: [210, 121, 145] },
  };

  try {    
    const [res1, res2] = await Promise.all([
      axios.put(API_URL_3, body1),
      axios.put(API_URL_4, body2),
    ]);

    console.log('Response 1:', res1);
    console.log('Response 2:', res2);
  } catch (err) {
    console.error(err.response || err);
  }
}

async function windowlightOFF() {
  const body1 = {
    dataType: "windowlightintensity",
    windowlightintensity: { windowvalueintensity: 0 },
  };

  const body2 = {
    dataType: "windowcolorlight",
    windowcolorlight: { colorforwindow: [210, 121, 145] },
  };

  try {   
    const [res1, res2] = await Promise.all([
      axios.put(API_URL_3, body1),
      axios.put(API_URL_4, body2),
    ]);

    console.log('Response 1:', res1);
    console.log('Response 2:', res2);
  } catch (err) {
    console.error(err.response || err);
  }
}


// Define slider intensity for window

async function onSlidewindow(newValue) {

  console.log("Slider Value:", newValue);


    const normalizedValue = (newValue / 100) * 10;


    const body = {  

      dataType: "windowlightintensity",
      windowlightintensity: { windowvalueintensity: normalizedValue },
      
    };

  try {
    const res = await axios.put('https://autismolab.mynlab.digital/server', body);
    console.log(res);
  } catch (err) {
    console.log(err);
  }
}

// Define color for window
  const [selectedwindowColor, setSelectedwindowColor] = useState({ r: 255, g: 0, b: 0 });

    
  async function onColorwindowChange(newColor) {
    console.log('Selected Color:', newColor);
      setSelectedwindowColor(newColor.rgb);
      
  const body = {
    
    dataType: "windowcolorlight",
    windowcolorlight: { colorforwindow: [newColor.rgb.r, newColor.rgb.g, newColor.rgb.b] },
    
    };
      
    try {
      const res = await axios.put(
      'https://autismolab.mynlab.digital/server',body );
        console.log(res);
      } catch (err) {
      console.error(err);
      }
      }




  return (
    <Card extra={"w-full  p-3"}>
      <div className="relative mb-3 flex items-center justify-between pt-1">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Toggle
        </h4>
        
      </div>
      <div className="flex flex-col">
        {/* the custom checkbox desing added in src/index.js */}



      <div className="mt-2 flex items-center gap-3">        
          <label
            htmlFor="checkbox7"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Engine Volume
          </label>
        </div>

        <div className="mt-1 mb-8">
      <Slider handleslider={onSlideEngineVolume} />
      </div> 



        <div className="mt-3 flex items-center gap-3">
      <Switch handleswitch={(isChecked) => (isChecked ? turnEngineOn() : turnEngineOff())} />
      <label
        htmlFor="checkbox1"
        className="text-base font-medium text-navy-700 dark:text-white"
      >
        Flight Introduction
      </label>
    </div>

        <div className="mt-2 flex items-center gap-3">        
        <label
        htmlFor="checkbox7"
        className="text-base font-medium text-navy-700 dark:text-white"
        >
        Audio Volume
        </label>
        </div>

        <div className="mt-1 mb-5">
        <Slider handleslider={onSlideFlightIntroVolume}/>
        </div> 


    <div className="mt-4 flex items-center gap-3">
        <Switch handleswitch={(isChecked) => (isChecked ? InstructionOn() : InstructionOff())} />
          <label
            htmlFor="checkbox7"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Flight Instructions
          </label>
        </div>

        <div className="mt-2 flex items-center gap-3">        
        <label
        htmlFor="checkbox7"
        className="text-base font-medium text-navy-700 dark:text-white"
        >
        Audio Volume
        </label>
        </div>

        <div className="mt-1 mb-5">
        <Slider handleslider={onSlideFlightinstructionvolume}/>
        </div> 



        <div className="mt-4 flex items-center gap-3">
        <Switch handleswitch={(isChecked) => (isChecked ? startplane() : stopplane())} />
          <label
              htmlFor="switch2"  
              className="text-base font-medium text-navy-700 dark:text-white"
            >
              Start Airplane
          </label>
        </div>





        <div className="mt-4 flex items-center gap-3">
        <Switch handleswitch={(isChecked) => (isChecked ? showpeople() : hidepeople())} />
          <label
            htmlFor="checkbox7"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Show Passanger
          </label>
        </div>



        <div className="mt-4 flex items-center gap-3">
        <Switch handleswitch={(isChecked) => (isChecked ? openWindow() : closeWindow())} />
          <label
            htmlFor="checkbox7"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Open/Close Window
          </label>
        </div>









        <div className="mt-4 flex items-center gap-3">
  <Switch id="switch7" />
  <label
    htmlFor="checkbox7"
    className="text-base font-medium text-gray-500 dark:text-white"
  >
    Meal/food   (Under Development)
  </label>
</div>



        <div className="mt-4 flex items-center gap-3">
        <Switch handleswitch={(isChecked) => (isChecked ? turnExterior() : turnInterior())} />
          <label
            htmlFor="checkbox7"
            className="text-base font-medium text-gray-500 dark:text-white"            
          >
            Exterior / Interior (Under Development) 
          </label>
        </div>

        <div className="mt-4 flex items-center gap-3">
          <Switch id="switch4" />
          <label
            htmlFor="checkbox4"
            className="text-base font-medium text-gray-500 dark:text-white"
          >
            Turbulance (Under Development)
          </label>          
        </div>

        <div className="mt-1 mb-5 ">
        <Slider />
        </div> 

        <div className="mt-10 flex items-center gap-3">
        <Switch handleswitch={(isChecked) => (isChecked ? windowlightON() : windowlightOFF())} />
          <label
            htmlFor="checkbox7"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Window Lights
          </label>
        </div>

        <div className="mt-1 mb-5 ">
        <Slider handleslider={onSlidewindow} />
        </div> 

        <div className="mt-4">
        <Colorpicker onChangeComplete={onColorwindowChange} color={selectedwindowColor} />
        </div>

        <div className="mt-5 flex items-center gap-3">
        <Switch handleswitch={(isChecked) => (isChecked ? ceilinglightON() : ceilinglightOFF())} />
          <label
            htmlFor="checkbox7"
            className="text-base font-medium text-navy-700 dark:text-white"
          >
            Ceiling Lights
          </label>
        </div>

        <div className="mt-1 mb-5">
      <Slider handleslider={onSlide} />
      </div>  


      <div className="mt-4 mb-5">
        <Colorpicker onChangeComplete={onColorChange} color={selectedColor} />
      </div>













        
          

      </div>
    </Card>
  );
}

export default Notification;
