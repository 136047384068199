import ProjectA from "./components/Project_a";
import ProjectB from "./components/Project_b";
import ProjectC from "./components/Project_c";

const Learning = () => {
  return (
    <div className="flex w-full flex-col gap-2">
      <div className="w-ful mt-5 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">



      </div>
      {/* all project & ... */}

      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4 ">
          <ProjectA />
        </div>

        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4 ">
          <ProjectB />
        </div>

        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4 ">
          <ProjectC />
        </div>
      </div>

    </div>
  );
};

export default Learning;
