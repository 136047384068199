import React, { useRef, useEffect } from 'react';

function WebcamComponent({ id, isRecording }) {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  useEffect(() => {
    const startRecording = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(device => device.kind === 'videoinput');
        const audioDevices = devices.filter(device => device.kind === 'audioinput');

        if (cameras.length > id && audioDevices.length > 0) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: cameras[id].deviceId },
            audio: {
              deviceId: audioDevices[0].deviceId,
              sampleRate: 44100,
              channelCount: 2,
            },
          });

          if (videoRef.current) {
            videoRef.current.srcObject = stream;

            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = event => {
              chunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = () => {
              const blob = new Blob(chunksRef.current, { type: 'video/webm' });
              chunksRef.current = [];

              // Using real-time local timestamp in the 'YYYY/MM/DD_HH:mm' format
              const currentDate = new Date();
              const timestamp =
                currentDate.getFullYear() +
                '_' +
                (currentDate.getMonth() + 1).toString().padStart(2, '0') +
                '_' +
                currentDate.getDate().toString().padStart(2, '0') +
                '_time_' +
                currentDate.getHours().toString().padStart(2, '0') +
                ':' +
                currentDate.getMinutes().toString().padStart(2, '0');

              const filename = `recorded-webcams_${timestamp}.webm`;

              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;

              a.download = filename;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            };

            mediaRecorderRef.current.start();
          }
        } else {
          console.error(`No camera or audio input found with id ${id}`);
        }
      } catch (error) {
        console.error('Error accessing camera or audio:', error);
      }
    };

    if (isRecording) {
      startRecording();
    }

    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
      }
    };
  }, [id, isRecording]);

  return <video ref={videoRef} autoPlay muted width="100%" height="100%" style={{ objectFit: 'cover' }} />;
}

export default WebcamComponent;
